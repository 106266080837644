// Dependencies
import React from "react";
import { Link } from "gatsby";
// Hooks
// import useReveal from '../hooks/useReveal';
// Components
import Image from "../components/Image"
import { useFilters } from "../components/Filters"

// OLD FILTER LOGIC
// function oldUseFilters(type, form, location) {
// 	const { activeFilters } = useFilterState()
// 	const isFiltered = activeFilters && activeFilters.length < 1 ? true : (
// 		activeFilters.find(filter => {
// 			const has = (category, name, filter) => category?.find(category => filter?.category === name && category.slug === filter?.value)
// 			const hasType = has(type, 'type', filter)
// 			const hasForm = has(form, 'form', filter)
// 			const hasLocation = has(location, 'location', filter)
// 			return (hasType || hasForm || hasLocation) ? true : false
// 		})
// 	)
// 	return isFiltered ? true : false
// }

const ProjectLink = ({ slug, title, summary, thumbnail, type, form, location, heroImage }) => {

	// Content
	const image = thumbnail?.gatsbyImageData
	const to = `/projects/${slug}`
	const tags = [...(type || []), ...(form || []), ...(location || [])] // YUK...
	const isFiltered = useFilters(tags)

	return (
		isFiltered && (
			<Link to={to} className="block p3 link--yellow">
				{image && <Image image={image} alt={'Test'} />}
				<div className="outer">
					<p className="">{summary?.copy}</p>
					<h5 className="">{title}</h5>
					{/* <h5 className="color--highlight">{type?.map(t => `${t.title}, `)}{form?.map(f => `${f.title}, `)}{location?.map(l => `${l.title}, `)}</h5> */}
				</div>
			</Link>
		)
	)
}

export default ProjectLink