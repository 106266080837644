// Dependencies
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks & Helpers
import { useResetHeader } from "../hooks/useUpdateHeader"
// Components
import Filters, { FilterState } from "../components/Filters"
import Layout from "../components/Layout"
import ProjectLink from "../components/ProjectLink"

const Projects = ({ data }) => {

	const { projects } = data.allContentfulProjectsIndex.nodes[0]

	// Resets header colours back to black
	useResetHeader()

	return (
		<Layout headerTitle="Projects" title={`Projects | AFK`} hideLogo showFilterButton theme={{
			background: 'var(--yellow-bg)',
			highlight: 'var(--yellow)'
		}}>
			<div className="projects header-offset">
				<div className="grid-3 innerx4 guttersx4">
					{projects.map((entry, index) => (
						<ProjectLink {...entry} key={`${entry.id}${index}`} />
					))}
				</div>
			</div>

			<Filters type="projects" />
		</Layout>
	)
}

Projects.propTypes = {
  data: PropTypes.object.isRequired
}

export default Projects

export const ProjectsPageQuery = graphql`
  query ProjectsQuery {
		allContentfulProjectsIndex(
			limit: 1
		) {
			nodes {
				projects {
					...ProjectThumbnailFragment
				}
			}
		}
  }
`

export const ProjectThumbnailFragment = graphql`
	fragment ProjectThumbnailFragment on ContentfulProject {
		id
		title
		slug
		summary {
			copy: summary
		}
		thumbnail {
			file {
				contentType
				url
			}
			gatsbyImageData(
				layout: FULL_WIDTH
				
			)
		}
		heroImage {
			id
		}
		type {
			title
			slug
		}
		form {
			title
			slug
		}
		location {
			title
			slug
		}
	}
`